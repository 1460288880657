import { rgba } from 'polished';
import styled from 'styled-components';
import { motion } from 'framer-motion';

// Config import
import { defaultTransition } from '@config/transition';

export const Container = styled(motion.div).attrs(defaultTransition)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2rem;
  gap: 3rem;
  align-self: stretch;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;

  .deleteAllBags {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 0px;

    button {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.button_danger};
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 1.5rem;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 0.25rem;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: none;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.background_primary};
    border-radius: 0.25rem;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .titleRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.5rem;

    p {
      font-weight: 600;
      font-size: 1.25rem;
    }
  }

  .salutationRow {
    display: flex;
    flex-direction: column;
    gap: 1rem;


    .salutation {
      font-size: 1.5rem;
      font-weight: 600;

      @media screen and (max-width: 768px) {
        align-self: center;
      }
    }
    .text {
      font-size:  1.25rem;
      font-weight: 500;
      color: ${({ theme }) => theme.font_secondary};

      @media screen and (max-width: 768px) {
        align-self: center;
        font-weight: 600;
        font-size:  1rem;
      }
    }
  }

  .bagsRow {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    .dayBreak {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      .dateRow {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid ${({ theme }) => theme.font_secondary};
        padding-bottom: 0.25rem;

        .date {
          font-size: 1rem;
          font-weight: 600;
          color: ${({ theme }) => theme.font_secondary};
          margin-right: 1rem;
        }
        .hour {
          font-size: 1rem;
          font-weight: 400;
          color: ${({ theme }) => theme.font_secondary};
        }
      }
      .bagsSection {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;

        @media screen and (max-width: 1280px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (max-width: 480px) {
          grid-template-columns: 1fr;
        }
    }
  }

  .loadingIndicator {
    align-self: center;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 2rem;
  margin-left: 2rem;

  align-self: stretch;

  margin-top: 1rem;
  border-top: 0.188rem solid ${({ theme }) => theme.font_secondary};
  border-radius: 0.125rem;
  padding: 1rem 0;

  gap: 1rem;

  .action {
    max-width: 18rem;
    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
  }
`;

export const BagContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.background_primary};
  padding: 1.5rem;
  border-radius: 0.625rem;
  gap: 0.25rem;

  .topRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .quantity {
      font-weight: 700;
      font-size: 1rem;
    }
    button {
      .details {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: ${({ theme }) => theme.button_high};

        p {
          font-weight: 600;
          font-size: 0.875rem;
          @media screen and (max-width: 768px) {
            font-weight: 400;
          }
        }
        @media screen and (max-width: 768px) {
            font-size: 1.25rem;
        }
      }
    }
  }

  .middleRow {
    display: flex;
    flex-direction: column;
    color: #bdbdbd;
    font-size: 0.75rem;
    gap: 0.25rem;
    margin-bottom: .5rem;

    .salespersonRow {
      color: ${({ theme }) => theme.font_secondary};
    }
  }

  .bottomRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      color: #866C6D;
      margin-right: 2rem;
      /* box-shadow: none; */
      &:focus {
        background: none;
        box-shadow: none;
      }
      &:hover {
        background: none;
        box-shadow: none;
        color: ${({ theme }) => theme.button_low};
      }
    }

    .trash {
      color:${({ theme }) => theme.button_danger};
      margin-right: 0;

    }



    .rec-pagination {
      margin: 0;

      button {
        margin: 0.313rem;
      }


      .rec-dot_active {
          box-shadow: 0 0 1px 3px rgb(220 198 183);
        }
      }
    }

    button {
      font-size: 2rem;
      color: ${({ theme }) => theme.button_danger};
    }

  }
`;

export const CarouselContent = styled.div`
  width: 18rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  .background {
    position: absolute;
    height: 100%;
    width: 2.55rem;
    background-color: ${({ theme }) => theme.background_primary};
    right: 0;
    margin-bottom: 0.125rem;
  }

  button {
    background: none;
  }

  .rec-arrow {
    background: none;
    border: none;
  }

  .rec-arrow-left {
    box-shadow: none;
    position: absolute;
    z-index: 4;
  }

  .rec-arrow-right {
    box-shadow: none;
    position: absolute;
    z-index: 4;
    right: 0;
  }

  .rec-slider-container {
    margin: 0;
  }

  .carouselCard {
    img {
      max-height: 6.25rem;

      @media screen and (max-width: 768px) {
        max-height: 5.625rem;
      }
    }
  }

  @media screen and (max-width: 768px) {
    max-width: 16rem;
  }
`;

export const ImagesContent = styled.div`
  width: 18rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  button {
    background: none;
  }

  img {
    max-height: 6.25rem;
    margin-right: 0.375rem;

    @media screen and (max-width: 768px) {
      max-height: 5.625rem;
    }
  }

  @media screen and (max-width: 768px) {
    max-width: 16rem;
  }
`;

export const EmptySavedBagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex: 1;
  gap: 2.5rem;

  .title {
    margin-bottom: 4.5rem;
  }

  .icon {
  }

  p {
    font-size: 1.875rem;
    font-weight: 600;
    text-align: center;
    width: 25.875rem;
    max-width: 70%;

    &.orientation {
      font-size: 1.5rem;
      font-weight: 700;
      color: ${({ theme }) => theme.font_secondary};
    }

    &.text {
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.font_primary};
    }
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 21rem;
    max-width: 70%;
  }

  @media screen and (max-width: 375px) {
    p,
    .action {
      max-width: 95%;
    }
  }

  @media screen and (max-width: 425px) {
    padding-bottom: 5rem;

    .action {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 1rem;

      z-index: 2;

      display: grid;
      align-items: center;
      justify-content: center;

      width: 100%;
      max-width: 100%;

      border-top: 0.125rem solid ${({ theme }) => theme.background_secondary};
      background: ${({ theme }) => theme.background_low};
      box-shadow: 0rem 0rem 1.5rem 0.05rem
        ${({ theme }) => rgba(theme.background_low, 0.95)};
    }
  }
`;

export const MobileBagSummary = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.7);
  .summaryArea {
    position: absolute;
    right: 0;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    background-color: ${({ theme }) => theme.background_low};
    display: flex;
    flex-direction: column;
    .loading {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: -webkit-fill-available;
    }
    .orderSummaryComponent {
      display: flex;
      flex-direction: column;
      flex: 1;
      max-height: 100%;
      gap: 1.5rem;

      padding: 1rem;
      align-self: stretch;

      overflow: hidden;
      overflow-y: scroll;

      /* width */
      ::-webkit-scrollbar {
        width: 0.25rem;
      }
      /* Track */
      ::-webkit-scrollbar-track {
        background: none;
      }
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.background_low};
        border-radius: 0.25rem;
      }
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      .orderSummaryHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .backButton {
          button {
            p {
              font-weight: 400;
              text-decoration: underline;
              font-size: 0.75rem;
              color: ${({ theme }) => theme.font_danger};
            }
          }
        }
        .closeButton {
          button {
            color: ${({ theme }) => theme.font_high};
            svg {
              font-size: 1.5rem;
            }
          }
        }
      }
      .titleRow {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        /* justify-content: flex-start; */
        /* align-items: center; */
        .title {
          color: ${({ theme }) => theme.font_secondary};
          font-size: 1.25rem;
          font-weight: 600;
        }

        .info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-bottom: 1rem;
          border-bottom: 0.063rem solid ${({ theme }) => theme.font_secondary};
          gap: 0.25rem;

          font-weight: 400;
          font-size: 0.875rem;

          .salespersonRow {
            color: ${({ theme }) => theme.font_secondary};
            font-size: 0.875rem;
            font-weight: 500;
          }
        }
      }
      .outOfStockRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: solid 0.063rem ${({ theme }) => theme.font_secondary};
        padding-bottom: 1rem;

        .outOfStockButton {
          width: 45%;
          button {
            p {
              color: ${({ theme }) => theme.font_high};
            }
          }
        }

        .text {
          font-weight: 600;
          font-size: 1rem;
          color: ${({ theme }) => theme.font_danger};
          width: 55%;
        }
      }
      .orderSummaryContent {
        max-height: 100vh;
        .packages {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          height: 100%;
          max-height: inherit;
          width: 100%;

          .package {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            padding-bottom: 1rem;
            border-bottom: 0.063rem solid ${({ theme }) => theme.font_secondary};
            .packageTitle {
              display: flex;
              flex-direction: row;
              align-items: center;
              font-size: 1.25rem;
              color: ${({ theme }) => theme.font_secondary};
              margin-bottom: 0.5rem;

              p {
                font-weight: 600;
                font-size: 1rem;
                color: ${({ theme }) => theme.font_secondary};
                margin-left: 0.5rem;
              }
            }
            .packageContent {
              p {
                font-weight: 500;
                font-size: 0.875rem;
                color: ${({ theme }) => theme.font_secondary};
              }
            }
            .productCard {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              align-self: stretch;
              background: ${({ theme }) => theme.background_primary};
              border-radius: 0.5rem;
              width: 100%;
              min-height: 8.75rem;
              margin-right: 0.5rem;

              .image {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: stretch;
                align-self: stretch;
                max-width: 6rem;

                img {
                  border-top-left-radius: 0.5rem;
                  border-bottom-left-radius: 0.5rem;
                  object-fit: cover;
                  flex: 1;
                  align-self: stretch;
                  max-width: 6rem;
                }
              }

              .info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;

                height: 100%;
                width: 100%;
                padding: 2.625rem 1rem 2rem 1rem;
                overflow: hidden;
                gap: 0.5rem;

                .outOfStock {
                  p {
                    color: ${({ theme }) => theme.font_primary};
                    background-color: ${({ theme }) => theme.background_danger};
                    border-radius: 1.25rem;
                    font-weight: 400;
                    font-size: 0.75rem;
                    padding: 0.25rem 1rem;
                  }
                }

                .name {
                  font-size: 0.875rem;
                  font-weight: 400;
                  color: ${({ theme }) => theme.font_primary};
                  width: -webkit-fill-available;
                  margin-bottom: 0.25rem;
                  P {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }

                .sizeAndQuantityRow {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: space-between;
                  gap: 2rem;
                  .size {
                    p {
                      font-weight: 400;
                      font-size: 0.75rem;
                    }
                  }
                  .quantity {
                    p {
                      font-weight: 400;
                      font-size: 0.75rem;
                    }
                  }
                }

                .priceRow {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  .price {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 1rem;

                    font-size: 0.875rem;
                    font-weight: 400;
                    color: ${({ theme }) => theme.font_secondary};

                    .original_price {
                      text-decoration: line-through;
                      color: ${({ theme }) => theme.button_secondary};
                    }
                  }
                  .action {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    button {
                      .icon {
                        color: ${({ theme }) => theme.font_danger};
                        width: 0.875rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const MobileBagSummaryFooter = styled.div`
  /* position: relative;
  bottom: 0;
  right: 0; */
  width: -webkit-fill-available;
  /* min-height: -webkit-fill-available; */
  background-color: ${({ theme }) => theme.background_low};
  display: flex;
  flex-direction: column;
  z-index: 2;
  /* padding: 1rem; */
  gap: 1.5rem;

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding-top: 1.5rem;
    /* border-top: 0.063rem solid ${({ theme }) => theme.font_secondary}; */

    .infoRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: #bdbdbd;
      font-size: 0.875rem;
      font-weight: 400;

      .firstInfo {
        font-weight: 500;
      }

      .coupon {
        text-decoration-line: underline;
      }

      .discountValue {
        color: ${({ theme }) => theme.font_success};
      }
    }
  }

  .total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 1.25rem;
      font-weight: 600;
      color: ${({ theme }) => theme.font_primary};
    }
  }
`;
